import React from 'react';
import Select, { createFilter } from 'react-select';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

const customStyles = {
  menu: (provided) => ({
    ...provided,
    minWidth: 350,
  }),
};

const DomainSelect = ({ domains, selectedDomain, onChange, toggleFavorite, setDomains}) => {

  const options = [
    { value: 'all', label: 'All Domains', isFavorite: true }, 
    ...domains.map(domain => ({
      value: domain.id, 
      label: domain.name, 
      isFavorite: domain.is_favorite
    }))
  ];

  options.sort((a, b) => {
    if (a.value === 'all') return -1;
    if (b.value === 'all') return 1;
    
    if (a.isFavorite && !b.isFavorite) return -1;
    if (!a.isFavorite && b.isFavorite) return 1;
    
    return a.label.localeCompare(b.label);
  });

  const filterOption = createFilter({ ignoreCase: true });
  const customFilter = (option, rawInput) => {
    if (option.value === 'all') return true;
    return filterOption(option, rawInput);
  };
  

  const handleChange = (selectedOption) => {
    onChange({ target: { value: selectedOption ? selectedOption.value : '' } });
  };

  const formatOptionLabel = ({ value, label, isFavorite }) => (
    <div className="custom-select-option" onClick={(e) => e.preventDefault()}>
      {value !== 'all' && (
        <span onClick={(e) => {
          e.stopPropagation();
          toggleFavorite(value, !isFavorite, 'domain', setDomains);
        }}>
          {isFavorite ? (
            <FavoriteIcon className="star-icon always-visible" />
          ) : (
            <FavoriteBorderIcon className="star-icon star-border-icon" />
          )}
        </span>
      )}
      {label}
    </div>
  );
  

  return (
    <Select
      value={options.find(option => option.value === selectedDomain)}
      onChange={handleChange}
      formatOptionLabel={formatOptionLabel}
      options={options.sort((a, b) => b.isFavorite - a.isFavorite)}
      filterOption={customFilter}
      id="domain-select"
      styles={customStyles}
    />
  );
};


export default DomainSelect;
