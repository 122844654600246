import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const LineChart = ({ sortedErrors }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    let chartInstance;

    const ctx = chartRef.current.getContext('2d');
    const labels = Object.keys(sortedErrors).sort(); // Sort the labels in ascending order
    const errorMessages = getUniqueErrorMessages(sortedErrors); // Get unique error messages
    const datasets = [];

    // Combine similar error messages based on a common prefix
    const combinedErrorMessages = combineSimilarErrorMessages(errorMessages);

    // Initialize error counts to zero for each combined error message in every hour
    labels.forEach((hour) => {
      const errors = sortedErrors[hour];
      combinedErrorMessages.forEach((combinedErrorMessage) => {
        const matchedErrors = errors.filter((error) => error.error_message.startsWith(combinedErrorMessage));
        const errorCount = matchedErrors.reduce((total, error) => total + error.error_count, 0);

        let matchedDataset = datasets.find((ds) => ds.label === combinedErrorMessage);

        if (matchedDataset) {
          matchedDataset.data.push(errorCount);
        } else {
          const color = getRandomColor();
          datasets.push({
            label: combinedErrorMessage,
            data: [errorCount],
            borderColor: color,
            backgroundColor: color,
            tension: 0.1,
            fill: false,
          });
        }
      });
    });

    if (chartInstance) {
      chartInstance.destroy(); // Destroy previous chart instance
    }

    chartInstance = new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
            precision: 0,
            stacked: false
          },
          x: {
            stacked: false
          }
        },
        plugins: {
          legend: {
            position: 'bottom',
          },
        },
      },
      
    });

    return () => {
      // Clean up chart instance when the component is unmounted
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [sortedErrors]);

  // Get unique error messages from the sortedErrors object
  const getUniqueErrorMessages = (sortedErrors) => {
    const errorMessages = new Set();
    Object.values(sortedErrors).forEach((errors) => {
      errors.forEach((error) => {
        errorMessages.add(error.error_message);
      });
    });
    return Array.from(errorMessages);
  };

  // Combine similar error messages based on a common prefix
  const combineSimilarErrorMessages = (errorMessages) => {
    const combinedErrorMessages = new Set();
    errorMessages.forEach((errorMessage) => {
      const prefix = extractPrefix(errorMessage);
      combinedErrorMessages.add(prefix);
    });
    return Array.from(combinedErrorMessages);
  };

  // Extract the common prefix from the error message
  const extractPrefix = (errorMessage) => {
    const parts = errorMessage.split(':');
    if (parts.length > 1) {
      const prefix = parts[0].trim();
      return prefix;
    }
    return errorMessage;
  };

  // Generate a random color
  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  return <canvas ref={chartRef} style={{ maxHeight: '350px' }} />;
};

export default LineChart;