import React from 'react';

const Comparison = ({ data, item }) => {
    const periods = Object.keys(data.metrics);

    return (
        <div className="w-full p-2 flex content-center justify-evenly">
            {periods.map(period => {
                const periodData = data.metrics[period][item];
                const otherSiteData = data.metrics[period][1 - item];
                const differencePercent = Math.round(((periodData.ratio - otherSiteData.ratio) / otherSiteData.ratio) * 100);

                return (
                    <div key={period} className="flex flex-col content-center justify-center items-center">
                        <h3 className="text-sm uppercase text-zinc-400">{period}ays ratio</h3>
                        <div>
                            {differencePercent < 0 ? (
                                <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xl font-medium bg-rose-50 text-rose-500 dark:bg-red-500/10 dark:text-red-500 rounded-full hover:opacity-80 cursor-pointer">
                                    {periodData.ratio}%
                                </span>
                            ) : (
                                <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xl font-medium bg-lime-200 text-lime-700 rounded-full hover:opacity-80 cursor-pointer">
                                    {periodData.ratio}%
                                </span>
                            )}
                        </div>
                        <p className="text-zinc-400">({periodData.user_visits}/{periodData.registrations})</p>
                    </div>
                );
            })}
        </div>
    );
};

export default Comparison;
