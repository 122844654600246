import React, { useEffect, useState, useRef } from 'react';
import { DateRange } from 'react-date-range';
import styled from 'styled-components';
import { addDays, format } from 'date-fns';
import DomainSelect from './DomainSelect';
import LandingSelect from './LandingSelect';


const DateRangePickerWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const DateRangeInput = styled.input`
  padding: 8px 12px;
  font-size: 16px;
  height: 37px;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s;
  cursor: pointer;
`;

const DateRangePickerPopup = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 9999;
`;

const ButtonWrapper = styled.div`
  margin-left: 10px;
`;

const SubmitButton = styled.button`
  padding: 8px 20px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const Preloader = styled.div`
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #007bff;
  animation: spin 1s infinite linear;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const TableWrapper = styled.div`
  margin-top: 20px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const Th = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  background-color: #f2f2f2;
`;

const Td = styled.td`
  border: 1px solid #ddd;
  padding: 4px;
`;  

const FixedWidthTd = styled(Td)`
  width: 100px; /* Замените на желаемый фиксированный размер */
`;

const CountriesTable = ({ domains: initialDomainsData, landings: initialLandingData } ) => {
    const [domains, setDomains] = useState(initialDomainsData);
    const [landings, setLandings] = useState(initialLandingData);

    const [selectedDomain, setSelectedDomain] = useState('all');
    const [selectedLanding, setSelectedLanding] = useState('all');
    const [landingOptions, setLandingOptions] = useState([]);
    const [isDateRangePickerOpen, setIsDateRangePickerOpen] = useState(false);
    const dateRangePickerRef = useRef();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedDateRange, setSelectedDateRange] = useState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    ]);
    const [insights, setInsights] = useState([]);

    const findDomainNameById = (domainId) => {
        const domain = domains.find((domain) => domain.id === domainId);
        return domain ? domain.name : '';
      };
      
    const findLandingNameById = (landingId) => {
        const landing = landings.find((landing) => landing.id === landingId);
        return landing ? landing.name : '';
    };

    const handleDomainChange = (event) => {
        const selectedDomainId = event.target.value;
        setSelectedDomain(selectedDomainId);
        setSelectedLanding('all');
        setSelectedDomain(selectedDomainId);
      };
    
    const handleLandingChange = (event) => {
        setSelectedLanding(event.target.value);
    };

    useEffect(() => {
        if (selectedDomain === 'all') {
          setLandingOptions([{ id: 'all', name: 'All Landings' }]);
        } else {
          const filteredLandings = landings.filter(landing => landing.domain_id === parseInt(selectedDomain));
          setLandingOptions([{ id: 'all', name: 'All Landings' }, ...filteredLandings]);
        }
      }, [selectedDomain, landings]);
      
    const handleOutsideClick = (event) => {
        if (dateRangePickerRef.current && !dateRangePickerRef.current.contains(event.target)) {
          setIsDateRangePickerOpen(false);
        }
    };

    useEffect(() => {
        handleFormSubmit();
      }, [])
    
    const handleFormSubmit = () => {
        // Set isSubmitting state to true
        setIsSubmitting(true);

        const token = document.querySelector('meta[name="csrf-token"]').content;
        // POST API request to fetch errors and set them to sortedErrors state
        fetch('/api/countries/search', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': token,
            },
            body: JSON.stringify({
            selectedDomain,
            selectedLanding,
            dateRange: selectedDateRange,
            }),
        })
        .then((response) => response.json())
        .then((data) => {
            setInsights(data.insights);
            setIsSubmitting(false);
        });
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
          document.removeEventListener('mousedown', handleOutsideClick);
        };
      }, []);
      
      const handleDateRangeChange = (ranges) => {
        setSelectedDateRange([ranges.selection]);
      };
    
      const handleDateRangeFieldClick = () => {
        setIsDateRangePickerOpen(!isDateRangePickerOpen);
      };

      const sortedInsights = insights.sort((a, b) => {
        const domainComparison = a[0] - b[0];
        if (domainComparison !== 0) return domainComparison;
        return a[1] - b[1];
      });

      const toggleFavorite = async (itemId, newFavoriteStatus, type, setState) => {
        try {
          const response = await fetch('/favorites/update', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            body: JSON.stringify({ type: type, id: itemId, is_favorite: newFavoriteStatus })
          });
      
          if (response.ok) {
            // Update the local state to reflect the change
            setState(prevItems => prevItems.map(item => 
              item.id === itemId ? { ...item, is_favorite: newFavoriteStatus } : item
            ));
          } else {
            // Handle error
            console.error('Failed to update favorite status');
          }
        } catch (error) {
          console.error('Error updating favorite status:', error);
        }
      };

    return (
        <div>
          <div className="block">
          
          <DomainSelect
            domains={domains}
            selectedDomain={selectedDomain}
            onChange={handleDomainChange}
            toggleFavorite={toggleFavorite}
            setDomains={setDomains}
          />

         <LandingSelect
            landings={landingOptions}
            selectedLanding={selectedLanding}
            onChange={handleLandingChange}
            toggleFavorite={toggleFavorite}
            setLandings={setLandings}
         />
    
            <DateRangePickerWrapper ref={dateRangePickerRef}>
              <DateRangeInput
                type="text"
                value={`${format(selectedDateRange[0].startDate, 'dd/MM/yyyy')} - ${format(
                  selectedDateRange[0].endDate,
                  'dd/MM/yyyy'
                )}`}
                readOnly
                onClick={handleDateRangeFieldClick}
              />
              {isDateRangePickerOpen && (
                <DateRangePickerPopup>
                  <DateRange
                    ranges={selectedDateRange}
                    onChange={handleDateRangeChange}
                  />
                </DateRangePickerPopup>
              )}
            </DateRangePickerWrapper>
    
            <ButtonWrapper>
              {!isSubmitting ? (
                <SubmitButton onClick={handleFormSubmit}>Search</SubmitButton>
              ) : (
                <SubmitButton disabled>
                  <Preloader />
                </SubmitButton>
              )}
            </ButtonWrapper>
          </div>

          <div className="block2">
          {sortedInsights.map(([domainId, landingId, domainName, countries], index) => (
                <div key={`${domainId}-${landingId}`} className="landing-block">
                {Array.isArray(countries) && (
                    <table>
                        <thead>
                            <tr>
                                <Th colSpan={2}>{findDomainNameById(domainId)}/{findLandingNameById(landingId)}</Th>
                            </tr>
                            <tr>
                                <Th>Country</Th>
                                <Th>Count</Th>
                            </tr>
                        </thead>
                    <tbody>
                        {countries.map(([country, count]) => (
                        <tr key={`${domainId}-${landingId}-${country}`}>
                            <Td>{country}</Td>
                            <FixedWidthTd>{count}</FixedWidthTd>
                        </tr>
                        ))}
                    </tbody>
                    </table>
                )}
                </div>
            ))}
          </div>
        </div>
    );
};

export default CountriesTable;