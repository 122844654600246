import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';

const TimePicker = ({ selectedDateRange, onChange }) => {
  const formatTime = (date) => {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const isValidTime = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours >= 0 && hours < 24 && minutes >= 0 && minutes < 60;
  };
  const [startTime, setStartTime] = useState(formatTime(selectedDateRange[0].startDate));
  const [endTime, setEndTime] = useState(formatTime(selectedDateRange[0].endDate));

  const updateDateRange = () => {
    // Создаем новые объекты Date из исходных значений дат
    const newStartDate = new Date(selectedDateRange[0].startDate);
    const newEndDate = new Date(selectedDateRange[0].endDate);

    // Обновляем время для startDate и endDate
    const [startHours, startMinutes] = startTime.split(':').map(Number);
    newStartDate.setHours(startHours, startMinutes);

    const [endHours, endMinutes] = endTime.split(':').map(Number);
    newEndDate.setHours(endHours, endMinutes);

    onChange([{ startDate: newStartDate, endDate: newEndDate, key: 'selection' }]);
  };

  // Обработчики изменения времени
  const handleTimeChange = (setter) => (e) => {
    const newTime = e.target.value;
    // Разделяем строку времени на часы и минуты
    const [hours, minutes] = newTime.split(':').map(s => s.padStart(2, '0'));

    // Форматируем время с добавлением ведущих нулей, если необходимо
    const formattedTime = `${hours}:${minutes}`;

    // Проверяем, является ли введенное время допустимым
    if (isValidTime(formattedTime)) {
      setter(formattedTime);
    } else {
      // Сброс к последнему допустимому значению или ничего не делаем
      // setter(someLastValidValue);
    }
  };

  // Использование useEffect для вызова updateDateRange после обновления состояния
  useEffect(() => {
    updateDateRange();
  }, [startTime, endTime]);

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: '250px', // Задайте нужную ширину
      marginRight: '5px'
    }),
  };

  return (
    <div className="flex flex-evenly">
      <input
        type="text"
        value={startTime}
        onChange={handleTimeChange(setStartTime, true)}
        placeholder="HH:MM"
        className="input-time text-center w-50"
      />
      <input
        type="text"
        value={endTime}
        onChange={handleTimeChange(setEndTime, true)}
        placeholder="HH:MM"
        className="input-time text-center w-50"
      />
    </div>
  );
};

export default TimePicker;