import React from 'react';
import { Table, Column } from 'react-virtualized';
// import 'react-virtualized/styles.css'; // импортируйте стили

const VirtualizedTable = ({ requests }) => {
  return (
    <div>
      <Table
        width={800}
        height={400}
        headerHeight={20}
        rowHeight={30}
        rowCount={requests.length}
        rowGetter={({ index }) => requests[index]}
        >
        <Column label="Domain" width={200} dataKey="domain" cellRenderer={({ rowData }) => rowData.domain_id } />
        <Column label="Landing" width={200} dataKey="landing" cellRenderer={({ rowData }) => rowData.landing_id } />
        <Column label="Type" width={100} dataKey="request_type" cellRenderer={({ rowData }) => rowData.request_type} />
        <Column label="Data" width={100} cellRenderer={({ rowData }) => rowData.response && rowData.response.status} />
        <Column label="IP" width={100} cellRenderer={({ rowData }) => rowData.stats && rowData.stats.ip} />
        <Column label="Phone" width={100} cellRenderer={({ rowData }) => rowData.data && rowData.data.phone} />
        <Column label="Created At" width={150} dataKey="created_at" cellRenderer={({ rowData }) => rowData.created_at} />
        <Column
            label=""
            width={50}
            cellRenderer={({ rowData }) => (
            <a href={`/requests/${rowData.id}`} className="btn btn-primary btn-sm" target="_blank">
                More
            </a>
            )}
        />
</Table>

    </div>
  );
};

export default VirtualizedTable;
