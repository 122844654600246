import React, { useState } from 'react';
import styled from 'styled-components';

const PopupContainer = styled.div`
  position: absolute;
  top: 36px;
  width: 160px;
  left: 6px;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const CheckboxContainer = styled.div`
  margin-bottom: 5px;

  input[type='checkbox'] {
    margin-right: 8px;
  }
`;

const SubmitButton = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
`;

const ExportPopup = ({ fields, onSubmit }) => {
  const [selectedFields, setSelectedFields] = useState([]);

  const handleFieldChange = (field) => {
    if (selectedFields.includes(field)) {
      setSelectedFields(selectedFields.filter((f) => f !== field));
    } else {
      setSelectedFields([...selectedFields, field]);
    }
  };

  const handleSubmit = () => {
    onSubmit(selectedFields);
  };

  return (
    <PopupContainer>
      {fields.map((field) => (
        <CheckboxContainer key={field}>
          <input
            type="checkbox"
            id={field}
            checked={selectedFields.includes(field)}
            onChange={() => handleFieldChange(field)}
          />
          <label htmlFor={field}>{field}</label>
        </CheckboxContainer>
      ))}
      <SubmitButton onClick={handleSubmit}>Export</SubmitButton>
    </PopupContainer>
  );
};

export default ExportPopup;