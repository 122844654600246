import React, { useState, useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const hiddenEvents = [
  'sendFormWithoutConfirm_success',
  'sendFormWithoutConfirm_error',
  'registration_unfinished_sms',
  'registration_unfinished_error',
  'repeat_reg'
];

const MainChart = ({ groupedData }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    let chartInstance;

    const ctx = chartRef.current.getContext('2d');
    const labels = Object.keys(groupedData).sort(); // Sort the labels in ascending order
    const eventTypes = Object.keys(groupedData[labels[0]]); // Get the event types
    const datasets = [];
    const colors = {
      sendValidationSms_success: '#BBEDF0',
      sendValidationSms_error: '#67AFDF',
      sendForm_success: '#86E26E',
      sendForm_error: '#FFD38C',
      sendFormWithoutConfirm_success: '#4CB944',
      sendFormWithoutConfirm_error: '#fc7349',
    };

    datasets.push({
      type: 'line',
      yAxisID: 'y1', // Use the primary y-axis
      label: 'Registration Success Rate',
      data: labels.map((label) => groupedData[label].registrationSuccessRate || 0),
      borderColor: '#7EB84B', // Choose a color for the coefficient line
      backgroundColor: '#7EB84B', // Make the background transparent
      borderWidth: 1,
      fill: false,
      pointRadius: 0,
    });

    const { lineEvents, barEvents } = splitEvents(groupedData);

    // два успешных бар
    let barEventTypes = Object.keys(barEvents[labels[0]]);

    // остальное
    let lineEventTypes = Object.keys(lineEvents[labels[0]]);
  
    // Сортируем lineEventTypes так, чтобы _success были первыми
    lineEventTypes.sort((a, b) => {
      if (a.includes('_success') && !b.includes('_success')) {
        return -1;  // a идет первым
      } else if (!a.includes('_success') && b.includes('_success')) {
        return 1;   // b идет первым
      } else {
        return 0;   // порядок не изменяется
      }
    });

    // Сортируем barEventTypes аналогично
    barEventTypes.sort((a, b) => {
      if (a.includes('_success') && !b.includes('_success')) {
        return -1;  // a идет первым
      } else if (!a.includes('_success') && b.includes('_success')) {
        return 1;   // b идет первым
      } else {
        return 0;   // порядок не изменяется
      }
    });

    lineEventTypes.forEach((eventType, index) => {
      const data = labels.map((label) => groupedData[label][eventType]);
      const color = colors[eventType] || getRandomColor();

      datasets.push({
        type: 'line',
        yAxisID: 'y',
        label: eventType,
        data: data,
        borderColor: color,
        backgroundColor: color, // Set the fill color to the same as the line color
        tension: 0.1,
        fill: false, // Enable area fill
        borderWidth: 3, // Add a border width to differentiate overlapping datasets
        pointRadius: 0,
        hidden: hiddenEvents.includes(eventType),
        pointHoverRadius: 5, // Increase the hover radius for better interaction
        z: 3
      });
    });

    barEventTypes.forEach((eventType, index) => {
      const data = labels.map((label) => groupedData[label][eventType]);
      const color = colors[eventType] || getRandomColor();

      datasets.push({
        type: 'bar',
        yAxisID: 'y',
        label: eventType,
        data: data,
        borderColor: color,
        backgroundColor: color,
        order: barEventTypes.length - index,
        barPercentage: 1,
        z: 1
      });
    });

    if (chartInstance) {
      chartInstance.destroy(); // Destroy previous chart instance
    }

    chartInstance = new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
            precision: 0,
          },
          y1: {
            display: false,
          }
        },
        plugins: {
          legend: {
            position: 'bottom',
          },
          tooltip: {
            mode: 'index',
            intersect: false
          }
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: false
          },
          y1: {
            display: false,
          }
        },
        elements: {
          line: {
            tension: 0.4, // Adjust the line tension for smoother curves
          },
        },
        hover: {
          mode: 'index', // Enable mode 'index' to trigger hover events on all points with the same X axis index
          intersect: false, // Prevent overlapping hover events
        },
        tooltips: {
          intersect: false, // Prevent overlapping tooltips
          mode: 'index', // Enable mode 'index' to show tooltips for all points with the same X axis index
          callbacks: {
            label: handleTooltipLabel, // Customize the tooltip label
          },
        },
      },
    });

    return () => {
      // Clean up chart instance when the component is unmounted
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [groupedData]);

  const splitEvents = (groupedData) => {
    const lineEvents = {};
    const barEvents = {};
  
    for (const [time, events] of Object.entries(groupedData)) {
      lineEvents[time] = {};
      barEvents[time] = {};
  
      for (const [eventType, count] of Object.entries(events)) {
        if (
          eventType === 'sendForm_success' ||
          eventType === 'sendValidationSms_success' ||
          eventType === 'sendFormWithoutConfirm_success'
        ) {
          barEvents[time][eventType] = count;
        } else if (eventType !== 'registrationSuccessRate') {
          // Exclude registrationSuccessRate from lineEvents
          lineEvents[time][eventType] = count;
        }
      }
    }
  
    return { lineEvents, barEvents };
  };
  

  // Generate a random color
  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  // Customize the tooltip label
  const handleTooltipLabel = (context) => {
    const label = context.dataset.label;
    const value = context.parsed.y;
    const xLabel = context.chart.data.labels[context.dataIndex];
    const data = Object.entries(context.chart.data.datasets)
      .filter(([_, dataset]) => dataset.label !== label) // Filter out the selected dataset
      .map(([_, dataset]) => ({
        label: dataset.label,
        value: dataset.data[context.dataIndex],
      }));

    let tooltipLabel = `${label}: ${value}`;

    if (data.length > 0) {
      tooltipLabel += ` | ${xLabel}: ${data.map((entry) => `${entry.label}: ${entry.value}`).join(' | ')}`;
    }

    return tooltipLabel;
  };

  return <canvas ref={chartRef} style={{ maxHeight: '350px' }} />;
};

export default MainChart;
