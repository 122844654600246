import React, { useState } from 'react';

const PopupComponent = ({ texts }) => {
    const [popups, setPopups] = useState([]);

    const handleOpenPopup = () => {
        const newPopup = {
            text: texts[popups.length],
            id: popups.length + 1,
        };
        setPopups([...popups, newPopup]);
    };

    const handleClosePopup = (id) => {
        const updatedPopups = popups.filter((popup) => popup.id !== id);
        setPopups(updatedPopups);
    };

    return (
        <div>
            <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={handleOpenPopup}
            >
                Open Popup
            </button>
            {popups.map((popup, index) => (
                <div
                    key={popup.id}
                    className="bg-white border border-gray-300 rounded p-4 shadow"
                    style={{
                        width: '150px',
                        height: '50px',
                        position: 'fixed',
                        top: `${10 + index * 60}px`,
                        right: '10px',
                        transition: 'transform 0.5s ease-in-out',
                        transform: `translateY(${index * 60}px)`,
                        zIndex: 1000,
                        opacity: 0,
                        animation: 'fadeIn 0.5s forwards',
                    }}
                >
                    <p>{popup.text}</p>
                    <button
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                        onClick={() => handleClosePopup(popup.id)}
                    >
                        Close
                    </button>
                </div>
            ))}
            <style jsx>{`
                @keyframes fadeIn {
                    from {
                        opacity: 0;
                    }
                    to {
                        opacity: 1;
                    }
                }
            `}</style>
        </div>
    );
};

export default PopupComponent;