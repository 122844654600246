import React, { useState, useEffect, useRef } from 'react';
import DomainSelect from './DomainSelect';
import LandingSelect from './LandingSelect';
import { DateRange } from 'react-date-range';
import styled from 'styled-components';
import {format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import ExportPopup from './ExportPopup';
import toast, { Toaster } from 'react-hot-toast';
import Pagination from './Pagination';
import MainChart from './MainChart';
import isEqual from 'lodash/isEqual';
import TimePicker from './TimePicker';
import Cookies from 'js-cookie';
import RequestsGroup from './RequestsGroup';
import CountrySelect from './CountrySelect';

const customLocale = {
  ...enUS,
  options: {
    ...enUS.options,
    weekStartsOn: 1
  }
};

const DateRangePickerWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const DateRangeInput = styled.input`
  padding: 8px 12px;
  font-size: 16px;
  height: 37px;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s;
  cursor: pointer;
`;

const DateRangePickerPopup = styled.div`
  background-color: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 9999;
`;

const ExportButtonWrapper = styled.div`
  position: relative;
`;

const SubmitRequest = styled.button`
  padding: 8px 20px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 5px;
`;

const ButtonWrapper = styled.div`
  margin-left: 10px;
`;

const SubmitButton = styled.button`
  padding: 8px 20px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const Preloader = styled.div`
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #007bff;
  animation: spin 1s infinite linear;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const App = ({ domains: initialDomainsData, landings: initialLandingData }) => {

  const fields = ['request_type', 'created_at', 'url', 'referrer', 'promocode', 'phone', 'ip', 'fingerprint'];
  const [domains, setDomains] = useState(initialDomainsData);
  const [landings, setLandings] = useState(initialLandingData);

  const [queryHistory, setQueryHistory] = useState([]);
  const [tooltipCloseTimer, setTooltipCloseTimer] = useState(null);

  const [showDropdown, setShowDropdown] = useState(false);
  const [showPromocode, setShowPromocode] = useState(Cookies.get('showPromocode') === 'true');

  const [showPopup, setShowPopup] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState('all');
  const [selectedLanding, setSelectedLanding] = useState('all');
  const [landingOptions, setLandingOptions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requests, setRequests] = useState([]);
  const [isBlinking, setIsBlinking] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const fetchInProgressRef = useRef(false);
  const [chartData, setChartData] = useState({});
  const [customQuery, setCustomQuery] = useState('');
  const [totalRequests, setTotalRequests] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);
  const [snippets, setSnippets] = useState([]);
  const [selectedSnippet, setSelectedSnippet] = useState('');
  // startDate и endDate в selectedDateRange по умолчанию должны иметь время от 00:00 до 23:59
  const [selectedDateRange, setSelectedDateRange] = useState([
    {
      // startDate и endDate в selectedDateRange по умолчанию должны иметь время от 00:00 до 23:59
      startDate: new Date(new Date().setHours(0, 0, 0, 0)),
      endDate: new Date(new Date().setHours(23, 59, 59, 999)),
      key: 'selection',
    },
  ]);

  const [selectedCountry, setSelectedCountry] = useState({ value: 'All', label: 'All Countries' });

  const [visibleFields, setVisibleFields] = useState({
    promocode: false
  });

  const handleDropdown = (event) => {
    console.log(event);
    event.preventDefault();
    event.stopPropagation();
    setShowDropdown(prevShowDropdown => !prevShowDropdown);
  };
  
  const handleCheckboxChange = (event) => {
    const newShowPromocode = event.target.checked;
    setShowPromocode(event.target.checked);
    Cookies.set('showPromocode', newShowPromocode);
  };

  const findDomainNameById = (domainId) => {
    const domain = domains.find((domain) => domain.id === domainId);
    return domain ? domain.name : '';
  };

  const findLandingNameById = (landingId) => {
    const landing = landings.find((landing) => landing.id === landingId);
    return landing ? landing.name : '';
  };

  const [isDateRangePickerOpen, setIsDateRangePickerOpen] = useState(false);
  const dateRangePickerRef = useRef();

  useEffect(() => {
    fetch('/api/snippets')
      .then((response) => response.json())
      .then((data) => {
        setSnippets(data.snippets);
      })
      .catch((error) => {
        // Handle error
      });
  }, []);

  const toggleFavorite = async (itemId, newFavoriteStatus, type, setState) => {
    try {
      const response = await fetch('/favorites/update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify({ type: type, id: itemId, is_favorite: newFavoriteStatus })
      });

      if (response.ok) {
        // Update the local state to reflect the change
        setState(prevItems => prevItems.map(item =>
          item.id === itemId ? { ...item, is_favorite: newFavoriteStatus } : item
        ));
      } else {
        // Handle error
        console.error('Failed to update favorite status');
      }
    } catch (error) {
      console.error('Error updating favorite status:', error);
    }
  };



  useEffect(() => {
    if (selectedDomain === 'all') {
      setLandingOptions([{ id: 'all', name: 'All Landings' }]);
    } else {
      const filteredLandings = landings.filter(landing => landing.domain_id === parseInt(selectedDomain));
      setLandingOptions([{ id: 'all', name: 'All Landings' }, ...filteredLandings]);
    }
  }, [selectedDomain, landings]);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);


  const handleFormSubmit = async () => {
    if (!fetchInProgressRef.current) {
      setIsSubmitting(true);
      fetchInProgressRef.current = true; // Set fetchInProgress to true

      if (customQuery && !queryHistory.includes(customQuery)) {
        setQueryHistory([customQuery, ...queryHistory].slice(0, 10)); // Сохраняем последние 10 запросов
      }

      const page = currentPage === 0 ? 1 : currentPage;
      const token = document.querySelector('meta[name="csrf-token"]').content;
      try {
        const response = await fetch('/api/requests/search', {
          method: 'POST',
          body: JSON.stringify({
            selectedDomain,
            selectedLanding,
            dateRange: selectedDateRange,
            searchQuery,
            page,
            customQuery: customQuery,
            selectedSnippet,
            selectedCountry: selectedCountry.value
          }),
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': token,
          },
        });
        const data = await response.json();
        setRequests(data.requests);

        if (!chartData || !isEqual(data.chart_data, chartData)) {
          setChartData(data.chart_data);
        }

        setCurrentPage(data.current_page);
        setTotalPages(data.total_pages);
        setTotalRequests(data.total_requests);

        console.log("here");
        console.log(data.requests);
        // Функционал добавления полей в таблицу - 30.04
        if (data.requests) {
          // Создаем Set для сбора уникальных ключей
          const fieldsSet = new Set();

          // Перебираем все запросы и добавляем ключи в Set
          Object.values(data.requests).forEach(requestsGroup => {
            requestsGroup.forEach(req => {
              Object.keys(req.data).forEach(key => {
                fieldsSet.add(key);
              });
            });
          });

          console.log(fieldsSet);

          const newVisibleFields = Array.from(fieldsSet).reduce((acc, field) => {
            if (!(field in visibleFields)) {
              acc[field] = false;
              // Добавляем новое поле с видимостью false, если оно еще не было добавлено
            } else {
              acc[field] = visibleFields[field];
              // Сохраняем текущее состояние видимости, если поле уже было добавлено
            }
            return acc;
          }, {});

          setVisibleFields(newVisibleFields);
        }
      } catch (error) {
        // Handle error
      } finally {
        setIsSubmitting(false);
        fetchInProgressRef.current = false;
        // Reset fetchInProgress to false after the request is completed or in case of an error
        console.log(visibleFields);
      }
    }
  };

  const handleDateRangeChange = (ranges) => {
    // Получаем текущее время из предыдущего значения
    const currentStartDate = selectedDateRange[0].startDate;
    const currentEndDate = selectedDateRange[0].endDate;
  
    // Получаем часы и минуты из текущего времени
    const startHours = currentStartDate.getHours();
    const startMinutes = currentStartDate.getMinutes();
    const endHours = currentEndDate.getHours();
    const endMinutes = currentEndDate.getMinutes();
  
    // Устанавливаем новые даты с тем же временем
    const newStartDate = new Date(ranges.selection.startDate);
    newStartDate.setHours(startHours, startMinutes);
  
    const newEndDate = new Date(ranges.selection.endDate);
    newEndDate.setHours(endHours, endMinutes);
  
    // Обновляем состояние
    setSelectedDateRange([
      {
        startDate: newStartDate,
        endDate: newEndDate,
        key: 'selection',
      },
    ]);
  };

  const handleDateRangeFieldClick = () => {
    setIsDateRangePickerOpen(!isDateRangePickerOpen);
  };

  const handleOutsideClick = (event) => {
    if (dateRangePickerRef.current && !dateRangePickerRef.current.contains(event.target)) {
      setIsDateRangePickerOpen(false);
    }
  };

  const handleDomainChange = (event) => {
    const selectedDomainId = event.target.value;
    setSelectedDomain(selectedDomainId);
    setSelectedLanding('all');
    setSelectedDomain(selectedDomainId);
  };

  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
  };

  const handleCustomQueryChange = (event) => {
    const value = event.target.value;
    setCustomQuery(value);
  };

  const handleQuerySelect = (query, event) => {
    event.stopPropagation(); // Предотвращаем всплывание события
    setCustomQuery(query);
  };

  const renderTooltip = () => {
    return (
      <div className="tooltip">
        <span className="text-xs">Enter your custom query using the following format:</span>
        <ul>
          <li className="text-xs"><code className="code red">error:""</code> - search for all errors</li>
          <li className="text-xs"><code className="code red">error:"429"</code> - search for errors with specified response code</li>
          <li className="text-xs"><code className="code red">error:"text"</code> - search for errors with specified error text</li>
          <li className="text-xs"><code className="code red">event:sendForm_success</code> - or other request type name.</li>
        </ul>
        {queryHistory.length > 0 && (
        <div className="queries">
          <span>Recent Queries:</span>
          <ul>
            {queryHistory.map((query, index) => (
              <li className="query" key={index} onClick={(e) => handleQuerySelect(query, e)}>
                {query}
              </li>
            ))}
          </ul>
        </div>
      )}
      </div>
    );
  };

  const handleTableRowClick = (value) => {
    setSearchQuery(value);
    setIsBlinking(true);

    const timeout = setTimeout(() => {
      setIsBlinking(false);
    }, 600);

    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    handleFormSubmit();
  }, [currentPage])

  const handleLandingChange = (event) => {
    setSelectedLanding(event.target.value);
  };

  const handlePreviousPage = () => {
    // pervent page change if request is in progress
    if (isSubmitting) {
      console.log("Canno't change page while request is in progress");
      return;
    }
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (isSubmitting) {
      console.log("Canno't change page while request is in progress");
      return;
    }
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePageClick = (page) => {
    // Logic to handle individual page number button click
    if (isSubmitting) {
      console.log("Canno't change page while request is in progress");
      return;
    }
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleSnippetChange = (selectedOption) => {
    setSelectedSnippet(selectedOption);
  };

  const handleExportButtonClick = () => {
    // toggle the popup
    setShowPopup(!showPopup);
  };

  const handlePopupSubmit = (selectedFields) => {
    // Handle the submitted fields
    console.log('Selected fields:', selectedFields);

    sendReportRequest(selectedFields);
    // Close the popup
    setShowPopup(false);
  };

  const sendReportRequest = (selectedFields) => {
    // avoid ActionController::InvalidAuthenticityToken
    const token = document.querySelector('meta[name="csrf-token"]').content;
    fetch('/api/requests/export', {
      method: 'POST',
      body: JSON.stringify({
        selectedDomain,
        selectedLanding,
        dateRange: selectedDateRange,
        searchQuery,
        selectedFields,
        customQuery: customQuery
      }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        toast.success('Success! You can find your report in "Reports" section.');
      })
      .catch((error) => {
        // Handle error
      });
  };


  const handleTimeChange = (newDateRange) => {
    // Обновляем selectedDateRange при изменении времени
    setSelectedDateRange(newDateRange);
  };

  const handleFocus = () => {
    if (tooltipCloseTimer) {
      clearTimeout(tooltipCloseTimer);
      setTooltipCloseTimer(null);
    }
    setShowTooltip(true);
  };
  
  const handleBlur = () => {
    const timer = setTimeout(() => {
      setShowTooltip(false);
    }, 100); // Задержка в 100 мс
    setTooltipCloseTimer(timer);
  };

  const dropdownRef = useRef(null);
  const dropdownButtonRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownButtonRef.current && dropdownButtonRef.current.contains(event.target)) {
        // Если клик произошел на кнопке, не делаем ничего
        return;
      }
  
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleFieldVisibilityChange = (field) => {
    setVisibleFields(prevFields => ({
      ...prevFields,
      [field]: !prevFields[field] // Переключаем состояние видимости для данного поля
    }));
  }

  const activeFieldCount = Object.values(visibleFields).filter(v => v).length;
  const totalColumns = 8 + activeFieldCount; // 6 - это количество стандартных столбцов без динамически добавляемых

  return (
    <div className="wrapper">
      <div className="block sticky-top">

        <DomainSelect
          domains={domains}
          selectedDomain={selectedDomain}
          onChange={handleDomainChange}
          toggleFavorite={toggleFavorite}
          setDomains={setDomains}
        />

        <LandingSelect
          landings={landingOptions}
          selectedLanding={selectedLanding}
          onChange={handleLandingChange}
          toggleFavorite={toggleFavorite}
          setLandings={setLandings}
        />

        <CountrySelect
          selectedCountry={selectedCountry}
          onChange={setSelectedCountry}
        />

        <DateRangePickerWrapper ref={dateRangePickerRef}>
          <DateRangeInput
            type="text"
            value={`${format(selectedDateRange[0].startDate, 'dd/MM/yyyy HH:mm')} - ${format(
              selectedDateRange[0].endDate,
              'dd/MM/yyyy HH:mm'
            )}`}
            readOnly
            onClick={handleDateRangeFieldClick}
            className="datepicker-input"
          />
          {isDateRangePickerOpen && (
            <DateRangePickerPopup>
              <DateRange
                ranges={selectedDateRange}
                onChange={handleDateRangeChange}
                locale={customLocale}
              />
              <div className="time-picker">
              <TimePicker
                selectedDateRange={selectedDateRange}
                onChange={handleTimeChange}
              />
              </div>
            </DateRangePickerPopup>
          )}
        </DateRangePickerWrapper>



        <div className="pos-rltv">
          <input
            type="search"
            placeholder="Enter IP or Fingerprint or Phone"
            value={searchQuery}
            onChange={handleSearchInputChange}
            className={isBlinking ? 'search-input input-blink' : 'search-input'}
          />

        </div>

        <div style={{ position: 'relative' }}>
          <input
            type="search"
            placeholder="Custom Query"
            value={customQuery}
            onChange={handleCustomQueryChange}
            className="form-control search-input"
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          {showTooltip && renderTooltip()}
        </div>

        <ButtonWrapper>
          {!isSubmitting ? (
            <SubmitButton onClick={handleFormSubmit}>Search</SubmitButton>
          ) : (
            <SubmitButton disabled>
              <Preloader />
            </SubmitButton>
          )}
        </ButtonWrapper>

        <ExportButtonWrapper>
          <SubmitRequest onClick={handleExportButtonClick}>Export</SubmitRequest>
          {showPopup && <ExportPopup fields={fields} onSubmit={handlePopupSubmit} />}
          <Toaster />
        </ExportButtonWrapper>
        
        <div className="hs-dropdown relative [--placement:bottom-right]">
          <button ref={dropdownButtonRef} onClick={(event) => handleDropdown(event)} className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none">
          <svg
            className="flex-shrink-0 size-3.5"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M3 6h18"></path>
            <path d="M7 12h10"></path>
            <path d="M10 18h4"></path>
          </svg>
            
          </button>
          {showDropdown && (
              <div ref={dropdownRef} className="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 divide-y divide-gray-200 min-w-48 z-20 bg-white shadow-md rounded-lg mt-2 dark:bg-gray-800 dark:border dark:border-gray-700 block pos-fixed">
                {Object.keys(visibleFields).map((field) => (
                  <label key={field} className="flex py-2.5 px-3">
                    <input 
                      type="checkbox"
                      checked={visibleFields[field] || false}
                      onChange={() => handleFieldVisibilityChange(field)}
                      className="..."
                    />
                    <span className="ms-3 text-sm text-gray-800 dark:text-gray-200">
                      {field}
                    </span>
                  </label>
                ))}
              </div>
          )}
        </div>
        
      </div>


      {chartData && Object.keys(chartData).length > 0 ? (
        <div className="block">
          <MainChart groupedData={chartData} />
        </div>
      ) : null
      }

      {requests && Object.keys(requests).length > 0 ? (
        <div className="block block-column">

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            handlePreviousPage={handlePreviousPage}
            handleNextPage={handleNextPage}
            handlePageClick={handlePageClick}
          />


          {requests && totalRequests && totalRequests > 0 ? (
            <div style={{ marginBottom: '10px' }}>
              {totalRequests} {totalRequests === 1 ? 'request' : 'requests'}
            </div>
          ) : null}

          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50 dark:bg-slate-800">
              <tr>
                <th className="px-1 py-2 text-start whitespace-nowrap">Domain</th>
                <th className="px-1 py-2 text-start whitespace-nowrap">Landing</th>
                <th className="px-1 py-2 text-start whitespace-nowrap">Type</th>
                <th className="px-1 py-2 text-start whitespace-nowrap">Data</th>
                <th className="px-1 py-2 text-start whitespace-nowrap">IP</th>
                <th className="px-1 py-2 text-start whitespace-nowrap">Phone</th>
                <th className="px-1 py-2 text-start whitespace-nowrap">Created At</th>
                {Object.keys(visibleFields).filter(key => visibleFields[key]).map(key => (
                            <th key={key} className="px-1 py-2 text-start whitespace-nowrap">
                                {key.charAt(0).toUpperCase() + key.slice(1)} {/* Преобразование ключа в название */}
                            </th>
                ))}
                <th className="px-1 py-2 text-start whitespace-nowrap"></th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {Object.entries(requests).map(([fingerprint, requestsGroup]) => (
                <React.Fragment key={fingerprint}>
                  <tr className="fp row-click" onClick={() => handleTableRowClick(fingerprint.split('_')[0])}>
                      <td colSpan={totalColumns}>
                        <span>
                          Fingerprint: {fingerprint.split('_')[0]}
                        </span>
                      </td>
                  </tr>
                  <RequestsGroup
                    fingerprint={fingerprint}
                    requestsGroup={requestsGroup}
                    handleTableRowClick={handleTableRowClick}
                    findDomainNameById={findDomainNameById}
                    findLandingNameById={findLandingNameById}
                    setCustomQuery={setCustomQuery}
                    customQuery={customQuery}
                    showPromocode={showPromocode}
                    visibleFields={visibleFields}
                  />
                </React.Fragment>
              ))}
            </tbody>
          </table>
          <br />
          
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            handlePreviousPage={handlePreviousPage}
            handleNextPage={handleNextPage}
            handlePageClick={handlePageClick}
          />


          {requests && totalRequests && totalRequests > 0 ? (
            <div style={{ marginBottom: '10px' }}>
              {totalRequests} {totalRequests === 1 ? 'request' : 'requests'}
            </div>
          ) : null}

        </div>
      ) : null
      }
    </div>
  );
};

export default App;