import React from 'react';
import PropTypes from 'prop-types';

const MiniBarChart = ({ data }) => {
  const maxValue = Math.max(...data.map(item => item.value));
  const averageValue = data.reduce((sum, item) => sum + item.value, 0) / data.length;

  return (
    <div className="minibar minibar--mini">
      {data.map((item, index) => (
        <div className="minibar__bar" key={index}>
          <span className="minibar__tooltip">
            {item.value}%
            <br />
            {item.label.split('\n').map((line, i) => (
              <React.Fragment key={i}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </span>
          <div className="minibar__fill" style={{ height: `${(item.value / maxValue) * 100}%` }}></div>
        </div>
      ))}
    </div>
  );
};

MiniBarChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    })
  ).isRequired
};

export default MiniBarChart;
