import React from 'react';

const Pagination = ({ currentPage, totalPages, handlePreviousPage, handleNextPage, handlePageClick }) => {
    const renderPageLinks = () => {
        const links = [];
        const maxVisiblePages = 5; // Maximum number of visible page number links
        const ellipsis = <span className="ellipsis">...</span>;
      
        if (totalPages <= maxVisiblePages) {
          // If total pages is less than or equal to the maximum visible pages, show all page links
          for (let i = 1; i <= totalPages; i++) {
            links.push(
              <button
                key={i}
                onClick={() => handlePageClick(i)}
                className={currentPage === i ? 'active' : ''}
              >
                {i}
              </button>
            );
          }
        } else {
          // If total pages is greater than the maximum visible pages, add ellipsis and show selected page and its siblings
          let startPage = currentPage - Math.floor(maxVisiblePages / 2);
          if (startPage < 1) {
            startPage = 1;
          }
          let endPage = startPage + maxVisiblePages - 1;
          if (endPage > totalPages) {
            endPage = totalPages;
            startPage = endPage - maxVisiblePages + 1;
            if (startPage < 1) {
              startPage = 1;
            }
          }
      
          if (startPage > 1) {
            links.push(
              <React.Fragment key={1}>
                <button onClick={() => handlePageClick(1)}>1</button>
                {startPage > 2 && ellipsis}
              </React.Fragment>
            );
          }
      
          for (let i = startPage; i <= endPage; i++) {
            links.push(
              <button
                key={i}
                onClick={() => handlePageClick(i)}
                className={currentPage === i ? 'active' : ''}
              >
                {i}
              </button>
            );
          }
      
          if (endPage < totalPages) {
            links.push(
              <React.Fragment key={totalPages}>
                {endPage < totalPages - 1 && ellipsis}
                <button onClick={() => handlePageClick(totalPages)}>{totalPages}</button>
              </React.Fragment>
            );
          }
        }
      
        return links;
    };      

  return (
    totalPages > 1 && (
      <div className="pagination">
        <button onClick={handlePreviousPage} disabled={currentPage === 1} className="direction">
          Previous
        </button>
        {renderPageLinks()}
        <button onClick={handleNextPage} disabled={currentPage === totalPages} className="direction">
          Next
        </button>
      </div>
    )
  );
};

export default Pagination;
