import React, { useState } from 'react';

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    };
    return date.toLocaleString('en-GB', options);
};

const getRequestClass = (requestType) => {
    if (requestType.startsWith('registration_unfinished')) {
        return 'bg-amber-50 text-amber-500 dark:bg-amber-500/10 dark:text-amber-500';
    } else if (requestType === 'user_visit') {
        return 'bg-blue-50 text-blue-500 dark:bg-blue-500/10 dark:text-blue-500';
    } else if (requestType.includes('_success')) {
        return 'bg-lime-200 text-lime-700';
    } else if (requestType.includes("repeat_reg")){
        return 'bg-sky-200 text-sky-700'
    } else {
        return 'bg-rose-50 text-rose-500 dark:bg-red-500/10 dark:text-red-500';
    }
};

const RequestsGroup = ({
    fingerprint,
    requestsGroup,
    handleTableRowClick,
    findDomainNameById,
    findLandingNameById,
    setCustomQuery,
    customQuery,
    showPromocode,
    visibleFields
}) => {
    const [expandedGroups, setExpandedGroups] = useState({});

    const handleCustomQueryUpdate = (newQuery) => {
        if (customQuery && customQuery.startsWith('event:')) {
            setCustomQuery(customQuery + ',' + newQuery);
        } else {
            setCustomQuery('event:' + newQuery);
        }
    };

    const handleClick = (event, requestType) => {
        const ctrlPressed = event.ctrlKey || event.metaKey;
        if (ctrlPressed) {
            handleCustomQueryUpdate(requestType);
        } else {
            setCustomQuery('event:' + requestType);
        }
    };

    const groupedRequests = requestsGroup.reduce((acc, request) => {
        const lastGroup = acc[acc.length - 1];
        if (!lastGroup || lastGroup[0].request_type !== request.request_type) {
            acc.push([request]);
        } else {
            lastGroup.push(request);
        }
        return acc;
    }, []);

    const toggleGroup = (index) => {
        setExpandedGroups(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    return (
        <React.Fragment>
            {groupedRequests.map((group, index) => (
                <React.Fragment key={index}>
                    {expandedGroups[index] && (
                        <tr onClick={() => toggleGroup(index)} className="text-left cursor-pointer">
                            <td colSpan={Object.keys(visibleFields).length + 9}>
                                <div className="flex justify-end">
                                    <span className="py-1 px-2 flex justify-center w-14 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 transform inline-block">
                                        {expandedGroups[index] ? '▲' : '▼'}
                                    </span>
                                </div>
                            </td>
                        </tr>
                    )}
                    {!expandedGroups[index] && (
                        <tr className="text-left cursor-pointer">
                            <td>{findDomainNameById(group[0].domain_id)}</td>
                            <td>{findLandingNameById(group[0].landing_id)}</td>
                            <td>
                                <span
                                    onClick={(event) => { handleClick(event, group[0].request_type) }}
                                    className={`py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium ${getRequestClass(group[0].request_type)} rounded-full hover:opacity-80 cursor-pointer`}
                                >
                                    <svg className="size-2.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                                    </svg>
                                    <span className="font-medium">{group[0].request_type}{group.length > 1 ? ` x${group.length}` : ''}</span>
                                </span>
                            </td>
                            <td className="w-[40rem]">
                                {group[0].response && group[0].response.status && (
                                    <code className={`${group[0].response.status <= 200 ? 'green' : 'red'}`}>
                                        <span className="code">{group[0].response.status}</span>
                                        {group[0].response.message && <span className="message">{group[0].response.message}</span>}
                                    </code>
                                )}
                            </td>
                            <td onClick={() => handleTableRowClick(group[0].stats.ip)} className="row-click w-38" title={group[0].country_code}>
                                <div className="ip-flag">
                                    {group[0].country_code && <img src={`https://flagicons.lipis.dev/flags/4x3/${group[0].country_code.toLowerCase()}.svg`} className="flag-icon" />}
                                    <span>{group[0].stats.ip}</span>
                                </div>
                            </td>
                            <td className="row-click" onClick={() => handleTableRowClick(group[0].data.phone && group[0].data.phone.replace('+', ''))}>{group[0].data.phone && group[0].data.phone.replace('+', '')}</td>
                            <td className="w-48">{formatDate(group[0].created_at)}</td>
                            {Object.entries(group[0].data).map(([key, value]) => (
                                visibleFields[key] && (
                                    <td key={key}>{value}</td>
                                )
                            ))}
                            <td>
                                {group.length > 1 ? (
                                    <a className="py-1 px-2 flex justify-center w-14 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" target="_blank" onClick={() => group.length > 1 && toggleGroup(index)}>
                                        {expandedGroups[index] ? '▲' : '▼'}
                                    </a>
                                ) : (
                                    <a href={`/requests/${group[0].id}`} className="py-1 px-2 w-14 flex justify-center inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" target="_blank">
                                        More
                                    </a>
                                )}
                            </td>
                        </tr>
                    )}

                    {expandedGroups[index] && group.map((request, innerIndex) => (
                        <tr key={innerIndex}>
                            <td>{findDomainNameById(request.domain_id)}</td>
                            <td>{findLandingNameById(request.landing_id)}</td>
                            <td>
                                <span
                                    onClick={() => setCustomQuery("event:" + request.request_type)}
                                    className={`py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium ${getRequestClass(request.request_type)} hover:opacity-80 cursor-pointer rounded-full`}
                                >
                                    <svg className="size-2.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                                    </svg>
                                    {request.request_type}
                                </span>
                            </td>
                            <td className="w-[40rem]">
                                {request.response && request.response.status && (
                                    <code className={`${request.response.status <= 200 ? 'green' : 'red'}`}>
                                        <span className="code">{request.response.status}</span>
                                        {request.response.message && <span className="message">{request.response.message}</span>}
                                    </code>
                                )}
                            </td>
                            <td onClick={() => handleTableRowClick(request.stats.ip)} className="row-click w-38" title={request.country_code}>
                                <div className="ip-flag">
                                    {request.country_code && <img src={`https://flagicons.lipis.dev/flags/4x3/${request.country_code.toLowerCase()}.svg`} className="flag-icon" />}
                                    <span>{request.stats.ip} ({request.ip_counter})</span>
                                </div>
                            </td>
                            <td className="row-click" onClick={() => handleTableRowClick(request.data.phone && request.data.phone.replace('+', ''))}>{request.data.phone && request.data.phone.replace('+', '')}</td>
                            <td className="w-48">{formatDate(request.created_at)}</td>
                            {Object.entries(request.data).map(([key, value]) => (
                                visibleFields[key] && (
                                    <td key={key}>{value}</td>
                                )
                            ))}
                            <td>
                                <a href={`/requests/${request.id}`} className="py-1 px-2 inline-flex w-14 items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" target="_blank">
                                    More
                                </a>
                            </td>
                        </tr>
                    ))}
                </React.Fragment>
            ))}
        </React.Fragment>
    );
};

export default RequestsGroup;
