import React from 'react';
import Select from 'react-select';
import countryListAlpha2 from './countryList';

const countries = [
  { value: 'all', label: 'All Countries' },
  ...Object.entries(countryListAlpha2).map(([code, name]) => ({
    value: code,
    label: name,
  }))
];
const customStyles = {
  container: (provided) => ({
    ...provided,
    width: '250px', // Задайте нужную ширину
    marginRight: '5px'
  }),
};

const CountrySelect = ({ selectedCountry, onChange }) => (
  <Select
    value={selectedCountry}
    onChange={onChange}
    options={countries}
    isClearable={false}
    placeholder="Select a country"
    styles={customStyles}
  />
);

export default CountrySelect;
